import {
  Button,
  ButtonVariant,
  Icon,
  IconName,
  IconRotation,
} from '@tenset/components'

export interface SeeMoreButtonProps {
  isToggled: boolean
  onClick: () => void
  numberOfItemsToShow: number
}

export function SeeMoreButton({
  isToggled,
  onClick,
  numberOfItemsToShow,
}: SeeMoreButtonProps) {
  if (numberOfItemsToShow <= 0) return null

  return (
    <Button variant={ButtonVariant.Secondary} onClick={onClick}>
      <span>
        {isToggled ? 'See less' : 'See more'} &#40;{numberOfItemsToShow}&#41;
      </span>

      <Icon
        name={IconName.Chevron}
        rotation={isToggled ? undefined : IconRotation.DOWN}
      />
    </Button>
  )
}
